import React, { useState, useEffect } from 'react';
import './styles/Admin.css';
import ResultPage from '../adminPages/ResultsPage';
import AddQuestion from '../adminPages/AddQuestion';
import ViewQuestion from '../adminPages/ViewQuestion';

function Admin() {
    const [activeTab, setActiveTab] = useState('results');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="admin-container">
            <h1 className='admin-header'>Results Panel</h1>
            {/* <div className="tabs">
                <div
                    className={`tab ${activeTab === 'results' ? 'active' : ''}`}
                    onClick={() => handleTabChange('results')}
                >
                    Results
                </div>
                <div
                    className={`tab ${activeTab === 'addQuestions' ? 'active' : ''}`}
                    onClick={() => handleTabChange('addQuestions')}
                >
                    Add Questions
                </div>
                <div
                    className={`tab ${activeTab === 'viewQuestions' ? 'active' : ''}`}
                    onClick={() => handleTabChange('viewQuestions')}
                >
                    Questions
                </div>
            </div> */}
            <div className="tab-content">
                {activeTab === 'results' && <ResultsTab />}
                {/* {activeTab === 'addQuestions' && <AddQuestionsTab />}
                {activeTab === 'viewQuestions' && <ViewQuestionsTab />} */}
            </div>
        </div>
    );
}

function ResultsTab() {
    return (
        <div className="admin-container">
            <ResultPage />
        </div>
    );
}


function AddQuestionsTab() {
    return (
        <div className="admin-container">
            <AddQuestion />
            
        </div>
    );
}


function ViewQuestionsTab() {
    return (
        <div className="admin-container">
            <ViewQuestion />
            
        </div>
    );
}

export default Admin;
