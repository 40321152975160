import React, { useState } from 'react';
import questionsData from '../JsonQuestion/questions.json';
import '../pages/styles/ImageQuiz.css';
import Modal from './EncryptionComp/Modal';

const ImageQuiz = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [answersDisabled, setAnswersDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('Congratulations! Quiz is done \n تهانينا! انتهى الاختبار')

  const handleSelectAnswer = (answer) => {
    if (!answersDisabled) {
      setSelectedAnswer(answer);
      setAnswersDisabled(true);

      setTimeout(() => {
        const nextIndex = currentQuestionIndex + 1;
        if (nextIndex < questionsData.length) {
          setCurrentQuestionIndex(nextIndex);
          setSelectedAnswer(null);
          setAnswersDisabled(false);
        } else {
          setQuizCompleted(true);
          setIsModalOpen(true);  // Show modal when quiz is completed
        }
      }, 2000);
    }
  };

  const resetQuiz = () => {
    setCurrentQuestionIndex(0);
    setSelectedAnswer(null);
    setQuizCompleted(false);
    setAnswersDisabled(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    resetQuiz();
  };

  const currentQuestion = questionsData[currentQuestionIndex];

  return (
    <div className="image-quiz-container">
      <div className='image-modal'>
        <div className='quiz-image'>
          <img src="phishing-email sample.png" alt="Quiz Image" />
        </div>
      </div>
      <div className="image-quiz-modal">
        <h1 className='image-quiz-heading'>Quiz / الاختبار</h1>
        <div className="quiz-content">
          <h3 className='image-quiz-question'>{currentQuestion.question}</h3>
          <h3 className='image-quiz-question-ar'>{currentQuestion.question_ar}</h3>
          <ul className='image-quiz-options'>
            {currentQuestion.answers.map(answer => (
              <li key={answer.id}
                className={`answer ${selectedAnswer === answer ? (answer.correct ? 'correct' : 'incorrect') : ''}`}
                onClick={() => handleSelectAnswer(answer)}
                style={{ pointerEvents: answersDisabled ? 'none' : 'auto' }}>
                <span className='answer-text-left'>{answer.text}</span>
                <span className='answer-text-right'>{answer.text_ar}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      
      <Modal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
        message={modalMessage}
        duration={2000}  // Set modal duration to 2 seconds
      />
    </div>
  );
}

export default ImageQuiz;
