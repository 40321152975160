import React, { useState } from 'react';
import './styles/Encrypt.css';
import Modal from './EncryptionComp/Modal';

const Encrypt = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [quizMessage, setQuizMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalDuration, setModalDuration] = useState(5000); // Default to 5 seconds

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    handleSubmitQuiz(option); // Directly handle quiz submission when an option is selected
  };

  const handleSubmitQuiz = (option) => {
    if (option === 'option1') {
      setQuizMessage('Congratulations! Correct answer \nتهانينا! إجابة صحيحة');
      setModalDuration(5000); // Set modal duration to 5 seconds
      setIsModalOpen(true);
    } else {
      setQuizMessage('Incorrect answer, Please try again \n إجابة غير صحيحة، يُرجى المحاولة مجدداً');
      setModalDuration(2000); // Set modal duration to 2 seconds
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    resetQuiz();
  };

  const resetQuiz = () => {
    setSelectedOption(null);
    setQuizMessage('');
  };

  return (
    <div className='encryption-container'>
      <h1 className='heading-encryption'>Encryption / التشفير</h1>

      <div className="quiz-section">
        <div>
          <img src="/ROT13.png" alt="ROT13 Table" className="rot13-table" />
        </div>
        
        <div>
          <div className='encrypt-start-quiz-modal'>
            <div className='quiz-question-container'>
              <h3 className='encrypt-quiz-question english'>The message is encrypted using ROT13, use the table to select which option is the output of the encryption: <br/><span className="encrypt-highlight">Welcome to NCSA</span></h3>
              <h3 className='encrypt-quiz-question arabic'>تم تشفير الرسالة باستخدام ROT13، استخدم الجدول لتحديد الخيار الصحيح للتشفير: <br/><span className="encrypt-highlight">Welcome to NCSA</span></h3>
            </div>
            <ul className="quiz-options">
              <li 
                className={`encrypt-answer ${selectedOption === 'option1' ? 'correct' : ''}`} 
                onClick={() => handleOptionChange('option1')}
              >
                  Jrypbzr gb APFN
              </li>
              <li 
                className={`encrypt-answer ${selectedOption === 'option2' ? 'incorrect' : ''}`} 
                onClick={() => handleOptionChange('option2')}
              >
                  Jrpybzr gb AFPN
              </li>
              <li 
                className={`encrypt-answer ${selectedOption === 'option3' ? 'incorrect' : ''}`} 
                onClick={() => handleOptionChange('option3')}
              >
                  Jrypbqr gb APTN
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal} message={quizMessage} duration={modalDuration} />
    </div>
  );
};

export default Encrypt;
