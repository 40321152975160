import React, { useState, useEffect } from 'react';
import './styles/Quiz.css';

function Quiz() {
    const [username, setUsername] = useState('');
    const [userId, setUserId] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState({});
    const [quizStarted, setQuizStarted] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [quizFinished, setQuizFinished] = useState(false);
    const [quizScore, setQuizScore] = useState(0);

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await fetch('https://api.qncsa.qa2023.com/api/questions');
                if (!response.ok) {
                    throw new Error('Failed to fetch questions');
                }
                const data = await response.json();
                setQuestions(data);
            } catch (error) {
                console.error('Error fetching questions:', error);
            }
        };
        fetchQuestions();
    }, []);

    const handleUsernameChange = (event) => setUsername(event.target.value);

    const resetQuiz = () => {
        setUsername('');
        setUserId(null);
        setCurrentQuestionIndex(0);
        setUserAnswers({});
        setQuizStarted(false);
        setResultMessage('');
        setQuizFinished(false);
        setQuizScore(0);
    };

    const startQuiz = async () => {
        if (username) {
            try {
                const response = await fetch('https://api.qncsa.qa2023.com/api/quiz-users', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ username })
                });
                const data = await response.json();

                if (!response.ok) {
                    if (response.status === 409) {
                        throw new Error('The username has already been taken.');
                    } else if (response.status === 422) {
                        throw new Error('Validation error: ' + (data.message || 'Invalid data'));
                    } else {
                        throw new Error(data.message || 'Failed to start the quiz');
                    }
                }

                setUserId(data.id);
                setQuizStarted(true);
            } catch (error) {
                alert(error.message);
            }
        } else {
            alert('Please enter a username to start the quiz!');
        }
    };

    const selectAnswer = (questionId, answerId) => {
        const question = questions.find(q => q.id === questionId);
        const selectedAnswer = question.answers.find(answer => answer.id === answerId);

        setUserAnswers(prevAnswers => {
            const newAnswers = {
                ...prevAnswers,
                [questionId]: {
                    answerId: answerId,
                    isCorrect: selectedAnswer.is_correct
                }
            };
            console.log('Updated User Answers:', newAnswers); // Debugging log
            return newAnswers;
        });

        if (selectedAnswer.is_correct) {
            setQuizScore(prevScore => {
                const newScore = prevScore + 1;
                console.log('Updated Quiz Score:', newScore); // Debugging log
                return newScore;
            });
        }

        // Move to the next question or submit the quiz if it's the last question
        if (currentQuestionIndex < questions.length - 1) {
            setTimeout(() => {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
            }, 2000); // Add a 2-second delay to allow user to see the feedback
        } else {
            setTimeout(() => {
                // Ensure the score is updated before submitting
                setQuizScore(prevScore => {
                    console.log('Final Score for Submission:', prevScore); // Debugging log
                    submitQuiz(prevScore);
                    return prevScore;
                });
            }, 2000); // Add a 2-second delay to allow user to see the feedback
        }
    };

    const submitQuiz = async (score) => {
        console.log('Submitting Score:', score); // Debugging log

        try {
            const response = await fetch('https://api.qncsa.qa2023.com/api/quiz-users/finish-quiz', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    score: score
                })
            });

            const data = await response.json();
            console.log('Response from Server:', data); // Debugging log

            if (!response.ok) {
                throw new Error(data.message || 'Failed to finish the quiz');
            }

            setResultMessage(`Score/النتيجة \n (${data.score})`);
            setQuizFinished(true);

            setTimeout(() => {
                resetQuiz();
            }, 4000);
        } catch (error) {
            console.error("Error during quiz submission:", error);
            setResultMessage(`Error: ${error.message}`);
            setQuizFinished(true);

            setTimeout(() => {
                resetQuiz();
            }, 4000);
        }
    };

    if (quizFinished) {
        return (
            <div className="overlay">
                <div className="start-quiz-modal">
                    <h1 className="result-message">{resultMessage}</h1>
                </div>
            </div>
        );
    } else if (!quizStarted) {
        return (
            <div className="overlay">
                <div className="start-quiz-modal">
                    <h1 className='quiz-heading'>Quiz / الاختبار</h1>
                    <input type="text" placeholder="Enter your name / اكتب اسمك" value={username} onChange={handleUsernameChange} />
                    <button className='button-enabled' onClick={startQuiz}>Start / ابدأ</button>
                </div>
            </div>
        );
    }

    const currentQuestion = questions[currentQuestionIndex];
    return (
        <div className="overlay">
            <div className="modal">
                <div className="container">
                    <h1 className='quiz-heading'>Quiz / الاختبار</h1>
                    {currentQuestion ? (
                        <div style={{ textAlign: 'center' }}>
                            <h2 className='quiz-question-en'>{currentQuestion.text}</h2>
                            <h2 className='quiz-question-ar'>{currentQuestion.text_ar}</h2>
                            {currentQuestion.question_type === 'order' && (
                                <div>
                                    <div className='order-options-style'>
                                        <ol>
                                            <li className='option_style'>
                                                <span className='answer-text-left'>{currentQuestion.op2}</span>
                                                <span className='answer-text-right'>{currentQuestion.op2_ar}</span>
                                            </li>
                                            <li className='option_style'>
                                                <span className='answer-text-left'>{currentQuestion.op5}</span>
                                                <span className='answer-text-right'>{currentQuestion.op5_ar}</span>
                                            </li>
                                            <li className='option_style'>
                                                <span className='answer-text-left'>{currentQuestion.op3}</span>
                                                <span className='answer-text-right'>{currentQuestion.op3_ar}</span>
                                            </li>
                                            <li className='option_style'>
                                                <span className='answer-text-left'>{currentQuestion.op1}</span>
                                                <span className='answer-text-right'>{currentQuestion.op1_ar}</span>
                                            </li>
                                            <li className='option_style'>
                                                <span className='answer-text-left'>{currentQuestion.op4}</span>
                                                <span className='answer-text-right'>{currentQuestion.op4_ar}</span>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            )}

                            <ul className='quiz-options'>
                                {currentQuestion.answers.map(answer => {
                                    const isSelected = userAnswers[currentQuestion.id]?.answerId === answer.id;
                                    const isCorrect = userAnswers[currentQuestion.id]?.isCorrect;

                                    let answerClass = 'answer';
                                    if (isSelected) {
                                        answerClass += isCorrect ? ' correct' : ' incorrect';
                                    }

                                    return (
                                        <li key={answer.id}
                                            className={answerClass}
                                            onClick={() => selectAnswer(currentQuestion.id, answer.id)}>
                                            <span className='answer-text-left'>{answer.text}</span>
                                            <span className='answer-text-right'>{answer.text_ar}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    ) : <p>Loading questions...</p>}
                </div>
            </div>
        </div>
    );
}

export default Quiz;









// import React, { useState, useEffect } from 'react';
// import './styles/Quiz.css';

// function Quiz() {
//     const [username, setUsername] = useState('');
//     const [userId, setUserId] = useState(null);
//     const [questions, setQuestions] = useState([]);
//     const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//     const [userAnswers, setUserAnswers] = useState({});
//     const [quizStarted, setQuizStarted] = useState(false);
//     const [resultMessage, setResultMessage] = useState('');
//     const [quizFinished, setQuizFinished] = useState(false);
//     const [quizScore, setQuizScore] = useState(0);

//     useEffect(() => {
//         const fetchQuestions = async () => {
//             try {
//                 const response = await fetch('https://api.qncsa.qa2023.com/api/questions');
//                 if (!response.ok) {
//                     throw new Error('Failed to fetch questions');
//                 }
//                 const data = await response.json();
//                 setQuestions(data);
//             } catch (error) {
//                 console.error('Error fetching questions:', error);
//             }
//         };
//         fetchQuestions();
//     }, []);

//     const handleUsernameChange = (event) => setUsername(event.target.value);

//     const resetQuiz = () => {
//         setUsername('');
//         setUserId(null);
//         setCurrentQuestionIndex(0);
//         setUserAnswers({});
//         setQuizStarted(false);
//         setResultMessage('');
//         setQuizFinished(false);
//         setQuizScore(0);
//     };

//     const startQuiz = async () => {
//         if (username) {
//             try {
//                 const response = await fetch('https://api.qncsa.qa2023.com/api/quiz-users', {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json'
//                     },
//                     body: JSON.stringify({ username })
//                 });
//                 const data = await response.json();

//                 if (!response.ok) {
//                     if (response.status === 409) {
//                         throw new Error('The username has already been taken.');
//                     } else if (response.status === 422) {
//                         throw new Error('Validation error: ' + (data.message || 'Invalid data'));
//                     } else {
//                         throw new Error(data.message || 'Failed to start the quiz');
//                     }
//                 }

//                 setUserId(data.id);
//                 setQuizStarted(true);
//             } catch (error) {
//                 alert(error.message);
//             }
//         } else {
//             alert('Please enter a username to start the quiz!');
//         }
//     };

//     const selectAnswer = (questionId, answerId) => {
//         const question = questions.find(q => q.id === questionId);
//         const selectedAnswer = question.answers.find(answer => answer.id === answerId);

//         setUserAnswers(prevAnswers => {
//             const newAnswers = {
//                 ...prevAnswers,
//                 [questionId]: {
//                     answerId: answerId,
//                     isCorrect: selectedAnswer.is_correct
//                 }
//             };
//             console.log('Updated User Answers:', newAnswers); // Debugging log
//             return newAnswers;
//         });

//         if (selectedAnswer.is_correct) {
//             setQuizScore(prevScore => {
//                 const newScore = prevScore + 1;
//                 console.log('Updated Quiz Score:', newScore); // Debugging log
//                 return newScore;
//             });
//         }

//         // Move to the next question or submit the quiz if it's the last question
//         if (currentQuestionIndex < questions.length - 1) {
//             setTimeout(() => {
//                 setCurrentQuestionIndex(currentQuestionIndex + 1);
//             }, 2000); // Add a 2-second delay to allow user to see the feedback
//         } else {
//             setTimeout(() => {
//                 // Ensure the score is updated before submitting
//                 setQuizScore(prevScore => {
//                     console.log('Final Score for Submission:', prevScore); // Debugging log
//                     submitQuiz(prevScore);
//                     return prevScore;
//                 });
//             }, 2000); // Add a 2-second delay to allow user to see the feedback
//         }
//     };

//     const submitQuiz = async (score) => {
//         console.log('Submitting Score:', score); // Debugging log

//         try {
//             const response = await fetch('https://api.qncsa.qa2023.com/api/quiz-users/finish-quiz', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify({
//                     username: username,
//                     score: score
//                 })
//             });

//             const data = await response.json();
//             console.log('Response from Server:', data); // Debugging log

//             if (!response.ok) {
//                 throw new Error(data.message || 'Failed to finish the quiz');
//             }

//             setResultMessage(`Score/النتيجة \n (${data.score})`);
//             setQuizFinished(true);

//             setTimeout(() => {
//                 resetQuiz();
//             }, 4000);
//         } catch (error) {
//             console.error("Error during quiz submission:", error);
//             setResultMessage(`Error: ${error.message}`);
//             setQuizFinished(true);

//             setTimeout(() => {
//                 resetQuiz();
//             }, 4000);
//         }
//     };

//     if (quizFinished) {
//         return (
//             <div className="overlay">
//                 <div className="start-quiz-modal">
//                     <h1 className="result-message">{resultMessage}</h1>
//                 </div>
//             </div>
//         );
//     } else if (!quizStarted) {
//         return (
//             <div className="overlay">
//                 <div className="start-quiz-modal">
//                     <h1 className='quiz-heading'>Quiz / الاختبار</h1>
//                     <input type="text" placeholder="Enter your name / اكتب اسمك" value={username} onChange={handleUsernameChange} />
//                     <button className='button-enabled' onClick={startQuiz}>Start / ابدأ</button>
//                 </div>
//             </div>
//         );
//     }

//     const currentQuestion = questions[currentQuestionIndex];
//     return (
//         <div className="overlay">
//             <div className="modal">
//                 <div className="container">
//                     <h1 className='quiz-heading'>Quiz / الاختبار</h1>
//                     {currentQuestion ? (
//                         <div style={{ textAlign: 'center' }}>
//                             <h2 className='quiz-question-en'>{currentQuestion.text}</h2>
//                             <h2 className='quiz-question-ar'>{currentQuestion.text_ar}</h2>
//                             {currentQuestion.question_type === 'order' && (
//                                 <div>
//                                     <div className='order-options-style'>
//                                         <ol>
//                                             <li className='option_style'>
//                                                 <span className='answer-text-left'>{currentQuestion.op2}</span>
//                                                 <span className='answer-text-right'>{currentQuestion.op2_ar}</span>
//                                             </li>
//                                             <li className='option_style'>
//                                                 <span className='answer-text-left'>{currentQuestion.op5}</span>
//                                                 <span className='answer-text-right'>{currentQuestion.op5_ar}</span>
//                                             </li>
//                                             <li className='option_style'>
//                                                 <span className='answer-text-left'>{currentQuestion.op3}</span>
//                                                 <span className='answer-text-right'>{currentQuestion.op3_ar}</span>
//                                             </li>
//                                             <li className='option_style'>
//                                                 <span className='answer-text-left'>{currentQuestion.op1}</span>
//                                                 <span className='answer-text-right'>{currentQuestion.op1_ar}</span>
//                                             </li>
//                                             <li className='option_style'>
//                                                 <span className='answer-text-left'>{currentQuestion.op4}</span>
//                                                 <span className='answer-text-right'>{currentQuestion.op4_ar}</span>
//                                             </li>
//                                         </ol>
//                                     </div>
//                                 </div>
//                             )}

//                             <ul className='quiz-options'>
//                                 {currentQuestion.answers.map(answer => {
//                                     const isSelected = userAnswers[currentQuestion.id]?.answerId === answer.id;
//                                     const isCorrect = userAnswers[currentQuestion.id]?.isCorrect;

//                                     let answerClass = 'answer';
//                                     if (isSelected) {
//                                         answerClass += isCorrect ? ' correct' : ' incorrect';
//                                     }

//                                     return (
//                                         <li key={answer.id}
//                                             className={answerClass}
//                                             onClick={() => selectAnswer(currentQuestion.id, answer.id)}>
//                                             <span className='answer-text-left'>{answer.text}</span>
//                                             <span className='answer-text-right'>{answer.text_ar}</span>
//                                         </li>
//                                     );
//                                 })}
//                             </ul>
//                         </div>
//                     ) : <p>Loading questions...</p>}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Quiz;
