import React, { useRef } from 'react';
import './styles/ImageGallery.css';

function ImageGallery() {
    const imageUrls = Array.from({ length: 32 }, (_, i) => `image-gallery-images/${i + 1}.jpg`);

    const containerRef = useRef(null);

    const scrollAmount = 500; // Number of pixels to scroll each time

    const scrollUp = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                top: -scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    const scrollDown = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                top: scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    const scrollToImage = (index) => {
        if (containerRef.current) {
            const targetElement = containerRef.current.children[index];
            if (targetElement) {
                containerRef.current.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: 'smooth'
                });
            }
        }
    };

    return (
        <div className="image-gallery-container">
            <div ref={containerRef} className="image-gallery-image-container">
                {imageUrls.map((url, index) => (
                    <div key={index} className="image-gallery-image-wrapper">
                        <img src={url} alt={`Image ${index + 1}`} className="image-gallery-image" />
                    </div>
                ))}
            </div>
            <button onClick={scrollUp} className="image-gallery-scroll-button image-gallery-scroll-button-up">
                ↑ Scroll Up
            </button>
            <button onClick={scrollDown} className="image-gallery-scroll-button image-gallery-scroll-button-down">
                ↓ Scroll Down
            </button>
            <button onClick={() => scrollToImage(0)} className="image-gallery-scroll-button image-gallery-scroll-button-english">
                ENGLISH
            </button>
            <button onClick={() => scrollToImage(18)} className="image-gallery-scroll-button image-gallery-scroll-button-arabic">
                ARABIC
            </button>
        </div>
    );
}

export default ImageGallery;
