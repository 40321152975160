import React, { useState, useEffect } from 'react';
import './styles/PuzzleModal.css';

const PuzzleModal = ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [output, setOutput] = useState(null);
  const [displayedOutput, setDisplayedOutput] = useState("");

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setOutput("HHello, welcome!\nYour password is: M}$cN)hlP5Qn\nThis is a strong password!\n");
        setDisplayedOutput("");
      }, 2000);
    }
  }, [isOpen]);

  useEffect(() => {
    let intervalId = null;
    let closeTimeout = null;

    if (isOpen) {
      const printOutput = () => {
        let currentIndex = 0;
        intervalId = setInterval(() => {
          if (output !== null && currentIndex < output.length) {
            setDisplayedOutput(prevOutput => {
              const newOutput = prevOutput + output[currentIndex];
              const lines = newOutput.split('\n');
              if (lines.length > 3) {
                clearInterval(intervalId);
                closeTimeout = setTimeout(() => {
                  onClose();
                }, 3000);
                return lines.slice(0, 3).join('\n'); // Keep only the first three lines
              }
              return newOutput;
            });
            currentIndex++;
          } else {
            clearInterval(intervalId);
            if (!closeTimeout) {
              closeTimeout = setTimeout(() => {
                onClose();
              }, 3000);
            }
          }
        }, 50);
      };

      if (!loading && output !== null) {
        printOutput();
      }
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
      if (closeTimeout) clearTimeout(closeTimeout);
    };
  }, [loading, output, onClose, isOpen]);

  const handleCloseModal = () => {
    if (!loading) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={handleCloseModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {loading ? (
          <div className="loader-container">
            <h1 style={{ color: "#abb2bf" }}>Compiling Code</h1>
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <div className="output-container">
              <h1>Output!</h1>
              <pre className="output">{displayedOutput}</pre>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PuzzleModal;
