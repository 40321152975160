import React, { useState, useEffect } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './styles/Puzzle.css';
import PuzzleModal from './PuzzleModal';

// Function to shuffle an array
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

// Mapping from numbers to letters
const numberToLetter = {
  1: 'C',
  2: 'Y',
  3: 'B',
  4: 'E',
  5: 'R'
};

function CodeBlock({ code, number, placed, onClickPlace }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "code",
    item: { number },
    canDrag: !placed,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }), [placed]);

  return (
    <pre 
      ref={drag} 
      style={{ 
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: placed ? 'gray' : '#282c34',
        cursor: placed ? 'default' : 'grab',
        color: placed ? 'darkgray' : '#abb2bf',
      }} 
      className={`code-block ${isDragging ? 'dragging' : ''} ${placed ? 'placed' : ''}`}
      onClick={() => !placed && onClickPlace(number)}
    >
      <code>{code}</code>
    </pre>
  );
}

function QueueSlot({ number, filled, handleDrop }) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "code",
    drop: (item) => handleDrop(item.number, number),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div ref={drop} className="queue-slot" style={{ backgroundColor: filled ? 'lightgreen' : 'white' }}>
      {filled ? numberToLetter[filled] : numberToLetter[number]}
    </div>
  );
}

function Puzzle() {
  const initialBlocks = [
    { code: `# BLOCK C\n\npassword = "M}$cN)hlP5Qn"  # Define a sample password to test`, number: 1, placed: false },
    { code: `# BLOCK Y\n\ndef is_strong_password(password):  # Function to check if a password is strong\n    has_upper = any(char.isupper() for char in password)  # Check for uppercase letters\n    has_lower = any(char.islower() for char in password)  # Check for lowercase letters\n    has_number = any(char.isdigit() for char in password)  # Check for digits\n    has_special = any(char in "!@#$%^&*()" for char in password)  # Check for special characters\n    return len(password) >= 8 and has_upper and has_lower and has_number and has_special`, number: 2, placed: false },
    { code: `# BLOCK B\n\nis_strong = is_strong_password(password)  # Check if the password is strong`, number: 3, placed: false },
    { code: `# BLOCK E\n\nprint("Hello, welcome!")\nprint(f"Your password is: {password}")`, number: 4, placed: false },
    { code: `# BLOCK R\n\nif is_strong:\n    print("This is a strong password!")  # Output confirmation\nelse:\n    print("This password is weak. Try again.")  # Prompt for a stronger password`, number: 5, placed: false },
  ];

  const [blocks, setBlocks] = useState(() => shuffleArray([...initialBlocks]));
  const [queue, setQueue] = useState(Array(5).fill(null));
  const [isModalOpen, setModalOpen] = useState(false);
  const [nextExpectedBlock, setNextExpectedBlock] = useState(1);

  const placeBlock = (blockNumber, slotNumber) => {
    if (blockNumber === slotNumber) {
      setQueue(prevQueue => prevQueue.map((item, index) => (index + 1 === slotNumber && !item ? blockNumber : item)));
      setBlocks(prevBlocks => prevBlocks.map(block => block.number === blockNumber ? { ...block, placed: true } : block));
      setNextExpectedBlock(prev => prev + 1);
    }
  };

  const handleDrop = (blockNumber, slotNumber) => {
    placeBlock(blockNumber, slotNumber);
  };

  const handleAutoPlace = (blockNumber) => {
    if (blockNumber === nextExpectedBlock) {
      placeBlock(blockNumber, blockNumber);
    }
  };

  useEffect(() => {
    const isComplete = queue.every((item, index) => item === index + 1);
    if (isComplete) {
      setModalOpen(true);
    }
  }, [queue]);

  const handleCloseModal = () => {
    setModalOpen(false);
    resetPuzzle();
  };

  const resetPuzzle = () => {
    setBlocks(shuffleArray([...initialBlocks]).map(block => ({ ...block, placed: false })));
    setQueue(Array(5).fill(null));
    setNextExpectedBlock(1);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className='wrapper-puzzle'>
        <h1 className='puzzle-heading'>
          Match each code block to code stack items / قم بتوصيل كل مربع أخضر بالبرنامج المطابق
        </h1>

        <div className="main-container">
          <div className="queue-container">
            <h1 className='code-stack-heading'>Code Stack</h1>
            <div className="queue">
              {queue.map((number, index) => (
                <QueueSlot key={index} number={index + 1} filled={queue[index]} handleDrop={handleDrop} />
              ))}
            </div>
          </div>
          <div className="code">
            {blocks.map((block) => (
              <CodeBlock key={block.number} code={block.code} number={block.number} placed={block.placed} onClickPlace={handleAutoPlace} />
            ))}
          </div>
        </div>
        <PuzzleModal isOpen={isModalOpen} onClose={handleCloseModal}>
          <h1>Congratulations!</h1>
          <p>You have successfully completed the puzzle.</p>
        </PuzzleModal>
      </div>
    </DndProvider>
  );
}

export default Puzzle;
