import React, { useRef } from 'react';
import './styles/PDFReader.css';

function PDFReader() {
    const imageUrls = [
        `/page33.png`,
        `/page34.png`,
        `/page35.png`
    ];

    const containerRef = useRef(null);

    const scrollAmount = 500; // Number of pixels to scroll each time

    const scrollUp = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                top: -scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    const scrollDown = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                top: scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className="pdf-reader-container">
            <div ref={containerRef} className="image-container">
                {imageUrls.map((url, index) => (
                    <div key={index} className="image-wrapper">
                        <img src={url} alt={`Image ${index + 1}`} className="image" />
                    </div>
                ))}
            </div>
            <button onClick={scrollUp} className="scroll-button scroll-button-up">
                ↑ Scroll Up
            </button>
            <button onClick={scrollDown} className="scroll-button scroll-button-down">
                ↓ Scroll Down
            </button>
        </div>
    );
}

export default PDFReader;











// import React from 'react';

// function PDFReader() {
//     const imageUrls = [
//         `/page33.png`,
//         `/page34.png`,
//         `/page35.png`
//     ];

//     return (
//         <div style={{ height: '100vh', width: '100%', overflowY: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//             {imageUrls.map((url, index) => (
//                 <div key={index} style={{ marginBottom: '20px' }}>
//                     <img src={url} alt={`Image ${index + 1}`} style={{ maxWidth: '100%', height: 'auto' }} />
//                 </div>
//             ))}
//         </div>
//     );
// }

// export default PDFReader;












// import React, { useRef } from 'react';
// import { Worker, Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// // Import pdfjsLib
// import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';

// function PDFReader() {
//     pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

//     const viewerRef = useRef(null);


//     const defaultLayoutPluginInstance = defaultLayoutPlugin({
//         sidebarTabs: () => [],
//         // renderToolbar: () => [],
//     });

//     return (
//         <div style={{ height: '100vh', width: '100%' }}>
//             <div style={{ height: '100%', width: '100%' }}>
//                 <Worker workerUrl={`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`}>
//                     <Viewer
//                         ref={viewerRef}
//                         fileUrl={`${process.env.PUBLIC_URL}/QCF2022.pdf`}
//                         plugins={[defaultLayoutPluginInstance]}
//                         initialPage={33}
//                     />
//                 </Worker>
//             </div>
//         </div>
//     );
// }

// export default PDFReader;
