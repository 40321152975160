import React, { useState, useEffect } from 'react';
import './styles/Decrypt.css';

const Decrypt = () => {
  const [text, setText] = useState('kl, wklv phvvdjh lv vhfuhfw!');
  const [decryptedText, setDecryptedText] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  const decryptText = (inputText) => {
    const shift = 3;
    let result = '';

    for (let i = 0; i < inputText.length; i++) {
      let char = inputText[i];
      if (char.match(/[a-z]/i)) { 
        let code = inputText.charCodeAt(i);
        
        if (code >= 65 && code <= 90) {
          char = String.fromCharCode(((code - 65 - shift + 26) % 26) + 65);
        } else if (code >= 97 && code <= 122) { 
          char = String.fromCharCode(((code - 97 - shift + 26) % 26) + 97);
        }
      }
      result += char;
    }
    return result;
  };

  const handleDecryptClick = () => {
    const result = decryptText(text);
    setDecryptedText(result);
    setStatusMessage('');
  };

  useEffect(() => {
    if (decryptedText) {
      const clearTextTimeout = setTimeout(() => {
        setDecryptedText('');
        setStatusMessage('Clearing Message...');

        const clearedMessageTimeout = setTimeout(() => {
          setStatusMessage('');
        }, 2000);

        return () => clearTimeout(clearedMessageTimeout);

      }, 7000);

      return () => clearTimeout(clearTextTimeout);
    }
  }, [decryptedText]);

  return (
    <div className='decrypt-container'>
      <h1 className='heading-decryption'>Decryption</h1>
      <div>
        <input
          className='decryption-input'
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Enter text to decrypt"
        />
      </div>

      <button className='decrypt-button' onClick={handleDecryptClick}>Decrypt</button>
      <div>
        <pre className="code-block-decrypted">
          {/* Display "Decrypted: " only if decryptedText is not empty, otherwise show statusMessage */}
          <code>{decryptedText ? `Decrypted: ${decryptedText}` : statusMessage}</code>
        </pre>
      </div>
    </div>
  );
};

export default Decrypt;
